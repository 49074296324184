import { TWordsSliceState } from "../redux/wordsSlice";

export default function updateLetterColours(state: TWordsSliceState) {
	const { correctLetters, misplacedLetters, incorrectlyGuessedLetters } = state;
	const greens = new Set(correctLetters);
	const oranges = new Set(misplacedLetters);
	const greys = new Set(incorrectlyGuessedLetters);
	const guess = state.currentGuess;
	const target = state.gameDef.answer;

	for (let i = 0; i < guess.length; i++) {
		if (guess.charAt(i) === target.charAt(i)) {
			greens.add(guess.charAt(i));
		} else {
			if (target.indexOf(guess.charAt(i)) !== -1) {
				oranges.add(guess.charAt(i));
			} else {
				greys.add(guess.charAt(i));
			}
		}
	}
	state.correctLetters = Array.from(greens);
	state.misplacedLetters = Array.from(oranges);
	state.incorrectlyGuessedLetters = Array.from(greys);
}
