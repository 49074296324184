import { distanceBetween } from "../../Shared/utils/distanceBetween";
import { getAngleBetween } from "../../Shared/utils/getAngleBetween";
import moveXY from "../../Shared/utils/moveXY";
import { POLYGON_SIDES } from "../constants";

export default function buildCandidate(type: TPolygonType, line: Line): TPolygon {
	const angle = getAngleBetween(line[0], line[1]);
	const distance = distanceBetween(line[0], line[1]);

	if (type === "RIGHT_ANGLE") {
		const rotate = 45;

		const shortDistance = Math.sqrt((distance * distance) / 2);

		const topLeft = line[0];
		const bottomLeft = line[1];
		const bottomRight = moveXY(topLeft, angle + rotate, shortDistance);

		const coords: [XY, XY, XY] = [topLeft, bottomRight, bottomLeft];

		const candidate: TRightAngle = { type: "RIGHT_ANGLE", coords: coords };
		return candidate;
	}

	const numSides = POLYGON_SIDES[type];
	const rotate = 360 / numSides;

	const coords = [line[0], line[1]];
	for (let i = 1; i < numSides - 1; i++) {
		const prev = coords[coords.length - 1];
		const next = moveXY(prev, angle + i * rotate, distance);
		coords.push(next);
	}
	// This maintains a "left handedness" of the polygons such that
	// if you drew the lines from p0 to p1, you would then turn left to go from p1 to p2
	coords.reverse();

	const candidate: TPolygon = { type: type, coords: coords };

	return candidate;
}
