import { Action, PayloadAction, createSlice } from "@reduxjs/toolkit";
import { checkWin } from "../utils/checkWin";
import { getUpdatedCircleDisabledStatuses } from "../utils/getUpdatedCircleDisabledStatuses";
import { growCircle } from "../utils/growCircle";
import { getUpdatedHouseStatuses } from "../utils/updateHouseStatuses";
import instantiateBubble from "../utils/instantiateBubble";

// export type boardState = TBoardDef

const initialState: TGameSliceState = {
	gameDefinition: { circles: [], houses: [] },
	circles: {},
	houses: {},
};

export type TGameSliceState = {
	gameDefinition: TBubbleGameDef;

	circles: { [key: string]: TBubbleState };
	houses: { [key: string]: THouseState };
	solution?: string[];
	solutionOrder?: number[];
	// errors: TErrorInfo;
	// validWords: TFoundWord[];
	won?: boolean;
};

const alphabet = "abcdefghijklmnopqrstuvwxyz".split("");

export const bubblesSlice = createSlice({
	name: "bubbles",
	initialState: initialState,
	reducers: {
		initialiseGame: (state, action: PayloadAction<TBubbleGameDef>) => {
			state.gameDefinition = action.payload;
			const { circles: circleDefs, houses: houseDefs } = action.payload;
			const circles: { [key: string]: TBubbleState } = {};
			const houses: { [key: string]: THouseState } = {};
			Object.values(circleDefs)
				.map(instantiateBubble)
				.forEach((a, index) => {
					circles[a.id] = a;
				});
			Object.values(houseDefs).forEach((a, index) => {
				const id = "hou_" + alphabet[index];
				houses[id] = { ...a, id: id, covered: false };
			});

			state.solutionOrder = action.payload.solutionOrder;
			state.solution = action.payload.solution;
			state.circles = circles;
			state.houses = houses;

			// state.gameState = gameState;
		},
		testGame: (state, action: PayloadAction<TBubbleWithRadius[]>) => {
			// state.gameDefinition = action.payload;
			// const { circles: circleDefs, houses: houseDefs, solution } = action.payload;

			const asMap: { [key: string]: TBubbleState } = {};
			action.payload.forEach((a, index) => {
				const id = "circ_" + alphabet[index];
				asMap[id] = { id, center: a.center, radius: a.radius, isOpen: !!a.radius, isDisabled: false };
			});

			state.circles = asMap;
		},
		closeCircle: (state, action: PayloadAction<string>) => {
			const target = state.circles[action.payload];
			if (!target) {
				throw Error("invalid id input to toggle circle");
			}

			target.isOpen = false;
			target.touches = undefined;
			state.houses = getUpdatedHouseStatuses(state);
			state.circles = getUpdatedCircleDisabledStatuses(state);
			// target.radius = 0;
		},
		resetGame: (state, action: Action) => {
			console.log("here");
			Object.values(state.circles).forEach((c) => {
				const target = state.circles[c.id];
				if (!target) {
					throw Error("invalid id input to toggle circle");
				}

				target.isOpen = false;
				target.touches = undefined;
				state.houses = getUpdatedHouseStatuses(state);
				state.circles = getUpdatedCircleDisabledStatuses(state);
				// 	state.circles[c.id].isOpen = false;
				// 	state.circles[c.id].radius = 0;
				// 	state.circles[c.id].isDisabled = false;
				// });
			});
		},
		openCircle: (state, action: PayloadAction<string>) => {
			const target = state.circles[action.payload];
			if (!target) {
				throw Error("invalid id input to toggle circle");
			}

			target.isOpen = true;

			const res = growCircle(target, Object.values(state.circles));
			console.log({ res });

			state.circles[target.id].isOpen = true;
			state.circles[target.id].radius = res.radius;
			state.circles[target.id].touches = res.target?.id;

			// const s = chainReactCircles(target, Object.values(state.circles));
			// state.circles = s;

			// let t= res.target

			// if (t!== undefined) {
			// 	const circlesCopy = JSON.parse(JSON.stringify(state.circles));
			// 	delete circlesCopy[target.id];

			// 	const res2 = growCircle(res.target, Object.values(circlesCopy));
			// 	state.circles[res.target.id].isOpen = true;
			// 	state.circles[res.target.id].radius = res2.radius;
			// 	state.circles[res.target.id].touches = res2.target?.id;
			// }

			Object.values(state.circles).forEach((c) =>
				c.touches ? (state.circles[c.id].isDisabled = true) : (state.circles[c.id].isDisabled = false),
			);

			state.houses = getUpdatedHouseStatuses(state);
			state.circles = getUpdatedCircleDisabledStatuses(state);
			if (checkWin(Object.values(state.houses))) {
				state.won = true;
			}
		},
	},
});

export const { initialiseGame, resetGame, testGame, closeCircle, openCircle } = bubblesSlice.actions;
export default bubblesSlice.reducer;
