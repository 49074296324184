import { TPolygonSliceState } from "../redux/polygonsSlice";
import arePointsSame from "./arePointsSame";
import movePolygon from "./movePolygon";

export default function checkAndUpdateWinStatus(state: TPolygonSliceState) {
	const amalgam = state.currentAmalgam;
	const solution = state.solution;
	if (solution.coords.length !== amalgam.coords.length) {
		return false;
	}

	const dx = solution.coords[0][0] - amalgam.coords[0][0];
	const dy = solution.coords[0][1] - amalgam.coords[0][1];

	// place them on top of each other by start point
	movePolygon(amalgam, [dx, dy]);

	let areSame = true;

	for (let index = 0; index < amalgam.coords.length; index++) {
		const userCoord = amalgam.coords[index];
		const solutionCoord = solution.coords[index];

		if (!arePointsSame(userCoord, solutionCoord)) {
			areSame = false;
			break;
		}
	}

	if (areSame) {
		state.won = true;
	}
}
