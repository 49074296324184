import { Action, PayloadAction, createSlice } from "@reduxjs/toolkit";
import { MAX_GUESSES } from "../constants";
import updateKnownMinLength from "../utils/updateKnownMinLength";
import updateLetterColours from "../utils/updateLetterColours";
import updateCurrentGuessIssues from "../utils/updateCurrentGuessIssues";

// export type boardState = TBoardDef

const initialState: TWordsSliceState = {
	gameDef: {
		answer: "",
		answers: [],
		characterLimit: 15,
		entityName: "",
		id: "",
		invalidWordMessage: "",
		keyboardLayout: { default: [] },
		title: "",
		validCharacters: [],
	},
	knownMinLength: 3,
	knownAnswerLength: undefined,
	correctLetters: [],
	misplacedLetters: [],
	incorrectlyGuessedLetters: [],
	currentGuess: "",
	currentGuessIssue: undefined,
	gameState: "",
	prevGuesses: [],
};

export type TWordsSliceState = {
	gameDef: TWordGameOptions;
	knownMinLength: number;
	knownAnswerLength: number | undefined;
	correctLetters: string[];
	misplacedLetters: string[];
	incorrectlyGuessedLetters: string[];

	currentGuessIssue: TCurrentGuessIssue | undefined;

	currentGuess: string;
	gameState: TGameResult;
	prevGuesses: string[];
};

export const wordsSlice = createSlice({
	name: "words",
	initialState: initialState,
	reducers: {
		initialiseGame: (state, action: PayloadAction<TWordGameOptions>) => {
			const def = { ...action.payload };
			def.fuse = undefined;
			def.wordSet = undefined;
			state.gameDef = def;
			state.currentGuessIssue = { type: "insufficient-characters", count: 0 };
		},
		submitWord: (state, action: Action) => {
			const guess = state.currentGuess;
			const target = state.gameDef.answer;

			updateKnownMinLength(state);
			updateLetterColours(state);

			state.prevGuesses = [...state.prevGuesses, guess];
			state.currentGuess = "";
			if (guess.length === target.length) {
				state.knownAnswerLength = target.length;
			}

			if (guess === target) {
				state.gameState = "WIN";
			} else if (state.prevGuesses.length >= MAX_GUESSES) {
				state.gameState = "LOSS";
			}
		},
		handleLetter: (state, action: PayloadAction<string>) => {
			const letter = action.payload;
			const maxSubmitLength = Math.min(state.knownAnswerLength || state.gameDef.characterLimit);
			const { currentGuess } = state;

			if (currentGuess.length < maxSubmitLength) {
				state.currentGuess = currentGuess + letter;
			}

			updateCurrentGuessIssues(state);
		},
		handleBackspace: (state, action: Action) => {
			const updated = state.currentGuess.substring(0, state.currentGuess.length - 1);
			// setCurrentWordLetters(uniqueLettersInWord(updated));
			// setCurrentGuess(updated);
			state.currentGuess = updated;
		},
	},
});

export const { initialiseGame, handleBackspace, handleLetter, submitWord } = wordsSlice.actions;
export default wordsSlice.reducer;
