import { useLocation } from "react-router-dom";
import { GAME_NAMES } from "../../constants";
import { error } from "loglevel";

export default function useGameName(): TGameName | "" {
	const l = useLocation();

	const candidate = l.pathname.substring(1);

	if (GAME_NAMES.includes(candidate as TGameName)) {
		return candidate as TGameName;
	}

	if (!candidate) {
		return "";
	}

	error("Unknown route / game name: " + l.pathname);
	throw Error("Unknown route / game name" + l.pathname);
}
