import { Suspense, lazy } from "react";
import GameIntro from "../Shared/components/GameIntro";
import PageLoader from "../Site/components/PageLoader";
import EnglishDictionary from "../Shared/dictionaries/EnglishDictionary";
const Game = lazy(() => import("./components/Game"));

const TilesGameLazy = () => {
	EnglishDictionary.instantiate();
	return (
		<GameIntro>
			<Suspense fallback={<PageLoader />}>
				<Game />
			</Suspense>
		</GameIntro>
	);
};

export default TilesGameLazy;
