import { RouteObject, createBrowserRouter } from "react-router-dom";
import HomePage from "./Site/components/HomePage";
import Navigation from "./Site/components/Navigation";
import BubblesGameLazy from "./Bubbles";
import FlowersGameLazy from "./Flowers";
import PolygonGameLazy from "./Polygons";
import TilesGameLazy from "./Tiles/index";
import WordsGameLazy from "./Words";

const routes: RouteObject[] = [
	{
		path: "/",
		element: <Navigation />,
		children: [
			{ path: "", element: <HomePage /> },
			{ path: "bubbles", element: <BubblesGameLazy /> },
			{ path: "flowers", element: <FlowersGameLazy /> },
			{ path: "tiles", element: <TilesGameLazy /> },
			{ path: "words/:wordGameId?", element: <WordsGameLazy /> },
			{ path: "polygons", element: <PolygonGameLazy /> },
		],
	},
];

export default function getRouter(): ReturnType<typeof createBrowserRouter> {
	return createBrowserRouter(routes);
}
