import { useMemo } from "react";
import { useLocation } from "react-router-dom";

const pageName = "Jessica's Games - Alpha";
const titles: { [key: string]: string } = {
	"/": pageName,
	"/flowers": "Flowers",
	"/bubbles": "Bubbles",
	"/words": "Words",
	"/tiles": "Tiles",
};

export default function usePageTitle() {
	const { pathname } = useLocation();

	const title = useMemo(() => {
		const title = titles[pathname] || "";
		return title;
	}, [pathname]);

	return title;
}
