import { debug } from "loglevel";
import getIntersectionOfLines from "./getIntersectionOfLines";

export default function doPolygonsIntersect(polygon: Line[], lines: Line[]): boolean {
	// rough heuristic for complete overlapping

	const r = polygon.find((p) => lines.find((l) => getIntersectionOfLines(l, p)));

	if (r) {
		debug("Polygon filtered as its lines intersect anothers'");
		// console.log(lines);
		// console.log(polygon);
		// console.log(r);
	}

	return !!r;
}
