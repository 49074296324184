import React from "react";
import Loader from "../../../Shared/components/Loader";

const PageLoader = () => {
	return (
		<>
			<Loader />
		</>
	);
};

export default React.memo(PageLoader);
