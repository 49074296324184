import { Suspense, lazy } from "react";
import GameIntro from "../Shared/components/GameIntro";
import PageLoader from "../Site/components/PageLoader";
const Game = lazy(() => import("./components/Game"));

const PolygonGameLazy = () => {
	return (
		<GameIntro>
			<Suspense fallback={<PageLoader />}>
				<Game />
			</Suspense>
		</GameIntro>
	);
};

export default PolygonGameLazy;
