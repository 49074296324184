export function getCurrentRack(board: TBoardState, rack: TRackDef) {
	const usedTiles: string[] = [];
	const availableTiles: TTileDef[] = [];
	board.forEach((t) => {
		if (t !== "" && t !== "#") {
			usedTiles.push(t);
		}
	});

	rack.forEach((tile, index) => {
		if (!usedTiles.includes(tile.id)) {
			availableTiles.push({ ...tile });
		}
	});
	return availableTiles;
}
