import { info } from "loglevel";

class EnglishDictionary {
	private static list: string[] | undefined;
	private static set: Set<string> | undefined;

	private constructor() {}

	public static isReady() {
		return !!this.list;
	}
	public static async instantiate() {
		if (!this.list) {
			info("Loading & Instantiating english dict");
			// this.list = (await import("./englishDictionary")).default;
			const res = await fetch(`./dictionaries/englishDictionary.txt`);
			const str = await res?.text();
			this.list = str.split(",");
			info("Dictionary ready");
		}
	}

	public static getList() {
		if (!this.list) {
			throw Error("dictionary not loaded");
		}
		return this.list;
	}
	public static getSet() {
		if (!this.set) {
			this.set = new Set(this.getList());
		}
		return this.set;
	}

	// public static getListUnsafe() {
	// 	return this.list as string[];
	// }

	// public static getSetUnsafe() {
	// 	return this.set as Set<string>;
	// }
}

export default EnglishDictionary;
