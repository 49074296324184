import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { getBoardValidity } from "./utils/checkWin";
import { getCurrentRack } from "./utils/getCurrentRack";
import { renderBoard } from "./utils/renderBoard";
import shuffleArray from "./utils/shuffleArray";

// export type boardState = TBoardDef

const initialState: TGameSliceState = {
	rack: [],
	board: ["", ""],
	gameDefinition: { gameId: "NONE", rackDef: [], boardDef: ["", ""] },

	gameValidity: {
		errors: { count: 0, invalidWords: [], islands: [] },
		isValid: true,
		validWords: [],
	},
};

type TSetTilePayload = {
	position: number;
	tileId: string;
};

type TGameSliceState = {
	activeTile?: string;
	rack: TRackDef;
	board: TBoardState;
	gameValidity: TGameValidity;
	gameDefinition: TGameDef;
	isOver?: number;
	dragSource?: TDragSource;
	// errors: TErrorInfo;
	// validWords: TFoundWord[];
	won?: boolean;
};

type TShuffleTilePayload = {
	fromIndex: number;
	toIndex: number;
};

export const tilesSlice = createSlice({
	name: "tiles",
	initialState: initialState,
	reducers: {
		setActiveTile: (state, action: PayloadAction<string>) => {
			state.activeTile = action.payload;
		},
		clearActiveTile: (state) => {
			state.activeTile = undefined;
		},
		setIsOver: (state, action: PayloadAction<number>) => {
			state.isOver = action.payload;
		},
		clearIsOver: (state, action: PayloadAction<undefined>) => {
			state.isOver = action.payload;
		},
		setDragSource: (state, action: PayloadAction<TDragSource>) => {
			state.dragSource = action.payload;
		},
		initialiseGame: (state, action: PayloadAction<TGameDef>) => {
			state.gameDefinition = action.payload;
			state.board = action.payload.boardDef;
			// state.rack = [...action.payload.rackDef, { letter: "F", id: "fake" }];
			state.rack = [...action.payload.rackDef];
		},
		shuffleTileToIndex: (state, action: PayloadAction<TShuffleTilePayload>) => {
			const { fromIndex, toIndex } = action.payload;
			const copy = [...state.rack];
			const temp = copy[toIndex];
			copy[toIndex] = copy[fromIndex];
			copy[fromIndex] = temp;
			state.rack = copy;
		},

		/// first going to 5th place

		placeTileAtIndex: (state, action: PayloadAction<TShuffleTilePayload>) => {
			// TODO not 10000% this works in every case
			let { fromIndex: f, toIndex: t } = action.payload;
			console.log(JSON.parse(JSON.stringify({ payload: action.payload })));
			console.log(JSON.parse(JSON.stringify({ orig: state.rack })));
			const tilesOnBoard = state.board.filter((sq) => !!sq && sq !== "#");

			// TODO ? the index given is the visual index not counting any hidden ones, try to adjust for that here. Don't go past end of rack
			for (let i = 0; i <= t && i < state.rack.length; i++) {
				if (tilesOnBoard.includes(state.rack[i].id)) {
					if (t + 1 < state.rack.length) {
						t = t + 1;
					}
				}
			}
			for (let i = 0; i <= f && i < state.rack.length; i++) {
				if (tilesOnBoard.includes(state.rack[i].id)) {
					if (f + 1 < state.rack.length) {
						f = f + 1;
					}
				}
			}

			console.log({ f });
			console.log({ t });
			console.log(tilesOnBoard);
			// t = translatedToIndex;
			// console.log(translatedToIndex);
			if (f === t) {
				return;
			}

			const forwards = f < t;
			const newOrder: TRackDef = [];
			for (let i = 0; i < state.rack.length; i++) {
				const toAdd: TRackDef = [];
				if (i === f) {
					continue;
				}

				toAdd.push(state.rack[i]);
				if (i === t) {
					toAdd.push(state.rack[f]);
				}
				if (!forwards) {
					toAdd.reverse();
				}
				newOrder.push(...toAdd);
			}
			state.rack = newOrder;
		},
		placeTile: (state, action: PayloadAction<TSetTilePayload>) => {
			if (state.won) {
				return state;
			}
			state.isOver = undefined;

			const { position, tileId } = action.payload;
			if (state.board[position] === "#") {
				return;
			}

			const currentPos = state.board.findIndex((sq) => sq === tileId);
			if (currentPos !== -1) {
				state.board[currentPos] = "";
			}
			state.board[position] = tileId;

			const renderedBoard = renderBoard(state.rack, state.board);
			state.gameValidity = getBoardValidity(renderedBoard);
			const remainingTiles = getCurrentRack(state.board, state.rack);

			if (remainingTiles.length === 0 && state.gameValidity.isValid) {
				state.won = true;
			}
		},
		removeTileFromBoard: (state, action: PayloadAction<string>) => {
			if (state.won) {
				return state;
			}
			const currentPos = state.board.findIndex((sq) => sq === action.payload);

			state.board[currentPos] = "";
		},
		shuffleRack: (state) => {
			state.rack = shuffleArray(state.rack); //TODO won't work with things on board
		},
		resetGame: (state) => {
			state.board = state.gameDefinition.boardDef;
			state.rack = state.gameDefinition.rackDef;
		},
	},
});

export const {
	removeTileFromBoard,
	clearIsOver,
	setIsOver,
	shuffleTileToIndex,
	initialiseGame,
	placeTile,
	shuffleRack,
	resetGame,
	setDragSource,
	placeTileAtIndex,
	setActiveTile,
	clearActiveTile,
} = tilesSlice.actions;
export default tilesSlice.reducer;
