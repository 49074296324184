import { distanceBetween } from "../../Shared/utils/distanceBetween";

export function growCircle(thisPoint: TBubbleState, points: TBubbleState[]): TBubbleGrowthResult {
	const otherCircles = points.filter((p) => p.id !== thisPoint.id);

	const areOpen = otherCircles.filter((p) => p.isOpen);
	const closestRing = areOpen.reduce(
		(prev, curr) => Math.min(distanceBetween(curr.center, thisPoint.center) - curr.radius, prev),
		10000,
	);

	const closestPoint = otherCircles.reduce(
		(prev, current) => Math.min(distanceBetween(current.center, thisPoint.center), prev),
		1000,
	);

	if (closestPoint < closestRing) {
		return {
			source: thisPoint,
			radius: closestPoint,
			target: otherCircles.find((c) => distanceBetween(c.center, thisPoint.center) === closestPoint), // TODO double searching inefficient
		};
	} else {
		return {
			source: thisPoint,
			radius: closestRing,
		};
	}
}

// export function chainReactCircles(thisPoint: TBubbleState, points: TBubbleState[]) {
// 	const asMap: { [key: string]: TBubbleState } = {};
// 	points.forEach((p) => (asMap[p.id] = p));

// 	let otherCircles = points.filter((p) => p.id !== thisPoint.id);
// 	let closestCircle: TBubbleState | undefined = thisPoint;
// 	let index = 0;
// 	while (closestCircle && index < 3) {
// 		if (!closestCircle) {
// 			throw Error();
// 		}
// 		index = index + 1;
// 		// const res = growCircle(closestCircle, points);

// 		const closestPoint = otherCircles.reduce(
// 			//@ts-ignore
// 			(prev, current) => Math.min(distanceBetween(current.center, closestCircle.center), prev),
// 			1000,
// 		); //@ts-ignore
// 		const target = otherCircles.find((c) => distanceBetween(c.center, closestCircle.center) === closestPoint);
// 		console.log("IN LOOP");
// 		console.log({ target });
// 		console.log({ asMap });

// 		// const target = res.
// 		// console.log({ res });

// 		asMap[closestCircle.id].isOpen = true;
// 		asMap[closestCircle.id].radius = closestPoint;
// 		asMap[closestCircle.id].touches = target?.id;

// 		otherCircles = otherCircles.filter((c) => !c.isOpen);
// 		closestCircle = getClosestCircle(closestCircle, otherCircles);
// 	}

// 	return asMap;
// }

// function getClosestCircle(thisPoint: TBubbleState, otherCircles: TBubbleState[]) {
// 	let closestDist = 999999;
// 	let closestCircle: TBubbleState | undefined = undefined;
// 	otherCircles.forEach((c) => {
// 		const dist = distanceBetween(c.center, thisPoint.center);
// 		if (dist < closestDist) {
// 			closestCircle = c;
// 			closestDist = dist;
// 		}
// 	});

// 	return closestCircle;
// }
