export const CANVAS_WIDTH = 300;
export const CANVAS_HEIGHT = 300;

export const POLYGON_SIDES: { [key in TPolygonType]: number } = {
	RIGHT_ANGLE: 3,
	EQUILATERAL: 3,
	SQUARE: 4,
	PENTAGON: 5,
	HEXAGON: 6,
};

export const BASIC_EQUILATERAL: [XY, XY, XY] = [
	[0, 1],
	[1, 1],
	[0.5, 0.144],
];
export const BASIC_RIGHT_ANGLE: [XY, XY, XY] = [
	[0, 0],
	[0, 1],
	[1, 1],
];
export const BASIC_SQUARE: [XY, XY, XY, XY] = [
	[0, 0],
	[0, 1],
	[1, 1],
	[1, 0],
];

//https://mathworld.wolfram.com/RegularPentagon.html
const c1 = Math.cos((Math.PI * 2) / 5) / 2;
const c2 = Math.cos(Math.PI / 5) / 2;
const s1 = Math.sin((Math.PI * 2) / 5) / 2;
const s2 = Math.sin((Math.PI * 4) / 5) / 2;

export const BASIC_PENTAGON: [XY, XY, XY, XY, XY] = [
	[0.5, 0],
	[0.5 + s1, 0.5 - c1],
	[0.5 + s2, 0.5 + c2],
	[0.5 - s2, 0.5 + c2],
	[0.5 - s1, 0.5 - c1],
];

const unitHexagon: [XY, XY, XY, XY, XY, XY] = [
	[1, 0.5],
	[0.75, 0.5 + Math.sqrt(3) / 4],
	[0.25, 0.5 + Math.sqrt(3) / 4],
	[0, 0.5],
	[0.25, 0.5 - Math.sqrt(3) / 4],
	[0.75, 0.5 - Math.sqrt(3) / 4],
];
export const BASIC_HEXAGON: [XY, XY, XY, XY, XY, XY] = unitHexagon;

export const BASIC_POLYGONS: { [key in TPolygonType]: XY[] } = {
	EQUILATERAL: BASIC_EQUILATERAL,
	HEXAGON: BASIC_HEXAGON,
	PENTAGON: BASIC_PENTAGON,
	RIGHT_ANGLE: BASIC_RIGHT_ANGLE,
	SQUARE: BASIC_SQUARE,
};
