import { Node } from "./amalgamatePolygons";

export default function getTopLeftmostPoint(points: XY[]) {
	points.reduce((prev, curr) => (prev[1] < curr[1] ? prev : prev[1] === curr[1] && prev[0] < curr[0] ? prev : curr));
}

export function getTopLeftmostNode(nodes: Node[]) {
	return nodes.reduce((prev, curr) =>
		prev.xy[1] < curr.xy[1] ? prev : prev.xy[1] === curr.xy[1] && prev.xy[0] < curr.xy[0] ? prev : curr,
	);
}
