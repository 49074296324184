import { HTML5toTouch } from "rdndmb-html5-to-touch";
import React from "react";
import { DndProvider } from "react-dnd-multi-backend";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import getRouter from "./routes";
import { store } from "./store";
import "./styles/index.scss";
import * as loglevel from "loglevel";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

loglevel.setDefaultLevel("DEBUG");
const router = getRouter();

root.render(
	<React.StrictMode>
		<DndProvider options={HTML5toTouch}>
			<Provider store={store}>
				<RouterProvider router={router} />
			</Provider>
		</DndProvider>
	</React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
