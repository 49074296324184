import { debug } from "loglevel";
import { CANVAS_HEIGHT, CANVAS_WIDTH } from "../constants";
import getPolygonExtremeties from "./getPolygonExtremities";

export default function isInBounds(polygon: TPolygon): boolean {
	const { minX, maxX, minY, maxY } = getPolygonExtremeties(polygon);

	const result = minX >= 0 && minY >= 0 && maxX <= CANVAS_WIDTH && maxY <= CANVAS_HEIGHT;

	if (!result) {
		debug("Filtering out polygon which is not in bounds: " + polygon.coords);
	}
	return result;
}
