import React from "react";
import styles from "./GameCard.module.scss";
import { Link } from "react-router-dom";
import cx from "classnames";
type Props = TGameCard;

const GameCard = ({ name, route, className, children }: Props) => {
	return (
		<Link className={cx(styles.gameCard, styles[name], styles[className || route])} to={route}>
			<h2>{name}</h2>
			{children}
		</Link>
	);
};

export default GameCard;
