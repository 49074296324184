import Tile from "../../../Tiles/components/Tile";
import GameCard from "../GameCard";
import cx from "classnames";
import styles from "./HomePage.module.scss";

type Props = {};

const games: TGameCard[] = [
	{
		name: "Tiles",
		route: "tiles",
		children: (
			<div>
				<Tile letter="Q" />
			</div>
		),
	},
	{
		name: "Words",
		route: "words",
	},
];

const prototypes: TGameCard[] = [
	{
		name: "Bubbles",
		route: "bubbles",
	},
	{
		name: "Flowers",
		route: "flowers",
	},
	{
		name: "Polygons",
		route: "polygons",
	},
];

const HomePage = (props: Props) => {
	return (
		<div className={cx(styles.homePage, "centerColumn")} id="homepage">
			{/* <h1> Jessica's Games - Open Beta</h1> */}

			<h2>Daily Games</h2>
			<div className={styles.gamesContainer}>
				{games.map((g) => (
					<GameCard key={g.name} {...g} />
				))}
			</div>

			<h2>Prototypes</h2>
			<div className={styles.gamesContainer}>
				{prototypes.map((g) => (
					<GameCard key={g.name} {...g} />
				))}
			</div>
		</div>
	);
};

export default HomePage;
