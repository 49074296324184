import getPolygonExtremeties from "./getPolygonExtremities";

export default function getPolygonCenter(polygon: TPolygon): XY {
	const { minX, maxX, maxY, minY } = getPolygonExtremeties(polygon);
	if (polygon.type === "SQUARE") {
		return [minX + (maxX - minX) / 2, minY + (maxY - minY) / 2];

		// return
	}

	if (polygon.type === "EQUILATERAL" || polygon.type === "RIGHT_ANGLE") {
		const sumX = polygon.coords.reduce((prev, curr) => curr[0] + prev, 0);
		const sumY = polygon.coords.reduce((prev, curr) => curr[1] + prev, 0);
		return [sumX / 3, sumY / 3];
	}

	return [0.5 * (minX + maxX), 0.5 * (minY + maxY)];
}
