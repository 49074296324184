const ArrowLeft = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			stroke="#000"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round">
			<line x1="20" y1="12" x2="4" y2="12" />
			<polyline points="10 18 4 12 10 6" />
		</svg>
	);
};

export default ArrowLeft;
