import ReactModal from "react-modal";
import styles from "./HelpModal.module.scss";
import { useSearchParams } from "react-router-dom";
import { useCallback } from "react";

ReactModal.setAppElement("#root");

type Props = {
	isOpen: boolean;
};

function HelpModal({ isOpen }: Props) {
	const [, setSearchParams] = useSearchParams();
	const onClose = useCallback(() => {
		setSearchParams((prev) => {
			console.log("in callback");
			console.log(prev);
			prev.delete("help");
			return prev;
		});
	}, [setSearchParams]);
	console.log("in help");
	console.log({ isOpen });

	// const { helpItems = [] } = gameOptions;
	return (
		<ReactModal className={styles.help} isOpen={isOpen} overlayClassName={styles.helpOverlay}>
			<button onClick={onClose}>✖</button>
			<h1
				style={{
					fontSize: "1.5rem",
				}}>
				Help v0.2
			</h1>
			<ul>
				<li>This is in beta</li>
				<li> help coming soon =]</li>
				{/* <li>If the number goes green your guess was the correct length</li>
				<li>Reset will give you a new word</li> */}
				{/* <li>Answers can be any length from 3-{gameOptions.characterLimit} chars</li>
				{helpItems.map((h, index) => (
					<li key={index}>{h}</li>
				))} */}
			</ul>
		</ReactModal>
	);
}

export default HelpModal;
