import { configureStore } from "@reduxjs/toolkit";
import bubblesSlice from "./Bubbles/redux/bubblesSlice";
import flowersSlice from "./Flowers/flowersSlice";
import tilesSlice from "./Tiles/tilesSlice";
import wordsSlice from "./Words/redux/wordsSlice";
import polygonsSlice from "./Polygons/redux/polygonsSlice";

export const store = configureStore({
	reducer: {
		bubbles: bubblesSlice,
		flowers: flowersSlice,
		tiles: tilesSlice,
		words: wordsSlice,
		polygons: polygonsSlice,
	},
	devTools: true,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
