import { TWordsSliceState } from "../redux/wordsSlice";

export default function updateKnownMinLength(state: TWordsSliceState): void {
	const { knownMinLength, currentGuess } = state;
	const answer = state.gameDef.answer;

	let newLatest = 0;
	for (let i = knownMinLength; i < currentGuess.length; i++) {
		if (currentGuess[i] === answer[i]) {
			newLatest = i;
		}
	}
	if (newLatest) {
		state.knownMinLength = newLatest + 1;
	}
}
