import { TPolygonSliceState } from "../redux/polygonsSlice";
import getAllPotentials from "./getPotentials";

export default function updateCandidates(state: TPolygonSliceState) {
	const next = state.gameDefinition.sequence[state.turnNumber];
	state.candidates = {};

	if (next && !state.won) {
		const potentials = getAllPotentials(Object.values(state.polygons), next);
		potentials.forEach((c, index) => {
			const id = `candidate-${state.turnNumber}-${index}`;
			const obj = { ...c, id, roundIndex: state.turnNumber, isCandidate: true };
			state.candidates[id] = obj;
		});
	}
}
