import { Link, useLocation } from "react-router-dom";
import ArrowLeft from "../../../Shared/icons/arrowLeft";
import styles from "./TopBar.module.scss";
import useGameName from "../../../Shared/hooks/useGameName";
import capitaliseWord from "../../../Words/utils/capitaliseWord";
import usePageTitle from "../../hooks/usePageTitle";

const TopBar = () => {
	const gameName = useGameName();
	const location = useLocation();
	const title = usePageTitle();
	return (
		<nav className={styles.nav}>
			<ul>
				{/* <li>
					<PLogo />
				</li> */}
				{gameName && (
					<li>
						<Link to={"/"}>
							<ArrowLeft />
						</Link>
					</li>
				)}
				<li>
					<h1>{capitaliseWord(title)}</h1>
				</li>
				{gameName && (
					<li>
						<Link to={location.pathname + "?help=true"}>?</Link>
					</li>
				)}
			</ul>
		</nav>
	);
};

export default TopBar;
