/**
 * Move polygon an XY amount. Mutate the polygon
 * @param poly
 * @param translation
 * @returns the same polygon with its coords mutated
 */
export default function movePolygon(poly: TPolygonOrAmalgam, translation: XY) {
	poly.coords.forEach((point) => {
		point[0] = point[0] + translation[0];
		point[1] = point[1] + translation[1];
	});

	return poly;
}
