/**
 * returns a new point [x,y]
 * @param point start point
 * @param angle angle to move in
 * @param distance distance to move
 * @returns a new point at the end
 */
export default function moveXY(point: XY, angle: number, distance: number): XY {
	const angleRadians = angle * (Math.PI / 180);
	let x = point[0] + Math.sin(angleRadians) * distance;
	let y = point[1] - Math.cos(angleRadians) * distance;

	return [x, y];
}
