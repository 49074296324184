import { TGameSliceState } from "../redux/bubblesSlice";
import { isHouseCovered } from "./isHouseCovered";

export function getUpdatedHouseStatuses(state: TGameSliceState) {
	const houses = state.houses;
	Object.values(houses).forEach((h) =>
		isHouseCovered(h, Object.values(state.circles)) ? (houses[h.id].covered = true) : (houses[h.id].covered = false),
	);
	console.log(JSON.parse(JSON.stringify({ houses })));
	return houses;
}
