import { debug, info } from "loglevel";
import { distanceBetween } from "../../Shared/utils/distanceBetween";
import { getAngleBetween } from "../../Shared/utils/getAngleBetween";
import moveXY from "../../Shared/utils/moveXY";
import getPolygonCenter from "./getPolygonCenter";
import getPolygonExtremeties from "./getPolygonExtremities";
import polygonToString from "./polygonToString";

function doPolygonsOverlap(a: TPolygon, b: TPolygon, leeway = 0) {
	const aInB =
		a.coords.find((point) => isInsidePolygon(point, b, leeway)) || isInsidePolygon(getPolygonCenter(a), b, leeway);
	const bInA =
		b.coords.find((point) => isInsidePolygon(point, a, leeway)) || isInsidePolygon(getPolygonCenter(b), a, leeway);

	if (aInB) {
		debug(
			`Polygon filtered as it overlaps others - its vertex or center ${aInB} is inside polygon ${polygonToString(b)}`,
		);
	}
	if (bInA) {
		debug(
			`Polygon filtered as it overlaps others -  its vertex or center ${bInA} is inside polygon ${polygonToString(a)}`,
		);
	}

	return Boolean(aInB || bInA);
}

export default function doesPolygonOverlapOthers(a: TPolygon, others: TPolygon[], leeway = 0) {
	return others.some((other) => doPolygonsOverlap(a, other, leeway));
}

export function isInsidePolygon(point: XY, polygon: TPolygon, leeway = 0) {
	if (polygon.type === "SQUARE") {
		return isInsideSquare(point, polygon, leeway);
	}
	if (polygon.type === "EQUILATERAL" || polygon.type === "RIGHT_ANGLE") {
		return isInsideTriangle(point, polygon, leeway);
	}

	return approxIsInsideNAgon(point, polygon, leeway);
}

/** approximate if a point is inside a pentagon/hexagon etc by finding its midpoint and "radius" and checking if it falls within that circle */
function approxIsInsideNAgon(point: XY, polygon: TPolygon, leeway = 0) {
	const center = getPolygonCenter(polygon);
	const polygonRadius = distanceBetween(center, polygon.coords[0]);

	return isInsideCircle(point, center, polygonRadius, leeway);
}

function isInsideCircle(point: XY, center: XY, radius: number, leeway = 0) {
	return distanceBetween(point, center) + leeway < radius;
}

function isInsideSquare(point: XY, square: TSquare, leeway = 0) {
	const [x, y] = point;

	const { minX, maxX, maxY, minY } = getPolygonExtremeties(square);

	const res = x > minX + leeway && x + leeway < maxX && y > minY + leeway && y + leeway < maxY;
	if (res) {
		console.log(
			"polygon filtered as its center: " + point.join(",") + " is inside this polygon: ",
			JSON.parse(JSON.stringify(square.coords)),
		);
	}

	return res;
}

function isInsideTriangle(point: XY, polygon: TPolygon, leeway = 0) {
	// warn("growing triangle");
	// warn(polygonToString(polygon));
	const grownPolygon = enlargePolygonFromCenter(polygon, 0);
	// warn(polygonToString(grownPolygon));
	const [v1, v2, v3] = grownPolygon.coords;

	const d1 = sign(point, v1, v2);
	const d2 = sign(point, v2, v3);
	const d3 = sign(point, v3, v1);

	const has_neg = d1 <= 0 || d2 <= 0 || d3 <= 0;
	const has_pos = d1 >= 0 || d2 >= 0 || d3 >= 0;

	return !(has_neg && has_pos);
}

function enlargePolygonFromCenter(t: TPolygon, increase: number) {
	if (!increase) {
		return t;
	}
	const center = getPolygonCenter(t);
	info("growing triangle from its center ", center);

	const newCoords = t.coords.map((point) => {
		const angle = getAngleBetween(center, point);
		return moveXY(point, angle, increase);
	});

	return { ...t, coords: newCoords };
}

function sign(p1: XY, p2: XY, p3: XY) {
	return (p1[0] - p3[0]) * (p2[1] - p3[1]) - (p2[0] - p3[0]) * (p1[1] - p3[1]);
}

// bool PointInTriangle (fPoint pt, fPoint v1, fPoint v2, fPoint v3)
// {
//     float d1, d2, d3;
//     bool has_neg, has_pos;

//     d1 = sign(pt, v1, v2);
//     d2 = sign(pt, v2, v3);
//     d3 = sign(pt, v3, v1);

//     has_neg = (d1 < 0) || (d2 < 0) || (d3 < 0);
//     has_pos = (d1 > 0) || (d2 > 0) || (d3 > 0);

//     return !(has_neg && has_pos);
// }
