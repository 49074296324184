import { debug } from "loglevel";
import { getAngleBetween } from "../../Shared/utils/getAngleBetween";
import arePointsSame from "./arePointsSame";
import getRightTurnDistance from "./getRightTurnDistance";
import { getTopLeftmostNode } from "./getTopLeftmostPoint";

export default function amalgamatePolygons(input: TPolygon[]): TAmalgam {
	const polygons: TPolygon[] = JSON.parse(JSON.stringify(input));

	const graph = new Graph();

	graph.buildGraph(polygons);
	return graph.getAmalgam();
}

class Graph {
	private nodes: Node[] = [];

	public buildGraph(polygons: TPolygon[]) {
		// Build list of nodes for each polygon - add to graph but also store in the array
		const polygonNodes: Node[][] = [];
		polygons.forEach((polygon) =>
			polygon.coords.forEach((point, pointIndex) => {
				if (pointIndex === 0) {
					polygonNodes.push([]);
				}
				const node = this.addNode(point);
				polygonNodes[polygonNodes.length - 1].push(node);
			}),
		);

		// add the edges of each polygon to the graph
		polygons.forEach((polygon, polyIndex) => {
			const thisPolyNodes = polygonNodes[polyIndex];
			for (let index = 0; index < polygon.coords.length - 1; index++) {
				const element = thisPolyNodes[index];
				const next = thisPolyNodes[index + 1];

				this.addEdge(element, next);
			}
			this.addEdge(thisPolyNodes[polygon.coords.length - 1], thisPolyNodes[0]); // last to first to close the loop
		});
	}

	private addNode(point: XY) {
		const existing = this.nodes.find((n) => arePointsSame(n.xy, point));
		if (existing) {
			debug("Skipping node as its a duplicate " + point);
			return existing;
		} else {
			const newNode = new Node(point);
			this.nodes.push(newNode);
			return newNode;
		}
	}
	private addEdge(from: Node, to: Node) {
		from.addEdge(to);
		to.addEdge(from);
	}

	public getAmalgam(): TAmalgam {
		const start = getTopLeftmostNode(this.nodes);

		debug("Getting outside path - starting with node" + start.xy);

		let currentAngle = 0;
		let currentNode: Node | undefined = start;
		start.isStart = true;
		const path: Node[] = [];

		while (currentNode) {
			currentNode.hasBeenVisited = true;
			path.push(currentNode);
			const nextNode = currentNode.leftMostTurn(currentAngle);
			const nextAngle = getAngleBetween(nextNode?.xy || [0, 0], currentNode.xy);

			debug("Next node is " + nextNode?.xy);
			currentNode = nextNode;
			currentAngle = nextAngle;
			debug("The angle to this next node is " + nextAngle);
			// const anglesToConnections = start.
			// const next = this.nodes.reduce((prev, current) => {

			//     const angleBetween = getAngleBetween(prev.xy, current.xy)

			// })
		}

		return {
			type: "AMALGAM",
			coords: path.map((node) => node.xy),
		};
	}
}

export class Node {
	private connections: Node[] = [];
	public isStart: boolean = false;
	public hasBeenVisited: boolean = false;
	constructor(public xy: XY) {}

	addEdge(to: Node) {
		const alreadyConnected = this.connections.find((c) => c === to);
		if (!alreadyConnected) {
			this.connections.push(to);
		}
	}

	leftMostTurn(currentAngle: number): Node | undefined {
		debug("Trying to find left most turn from point " + this.xy + " that is greater than angle " + currentAngle);

		const available = [...this.connections.filter((c) => !c.hasBeenVisited)];
		debug("available vertices: " + available.join("; "));

		const sorted = available.sort(
			(c1, c2) =>
				getRightTurnDistance(currentAngle, getAngleBetween(this.xy, c1.xy)) -
				getRightTurnDistance(currentAngle, getAngleBetween(this.xy, c2.xy)),
		);
		debug("sorted options");
		debug(sorted.map((s) => s.xy).join("; "));

		return sorted[0];
	}
}
