import cx from "classnames";
import { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { Outlet } from "react-router-dom";
import useGameName from "../../../Shared/hooks/useGameName";
import usePageTitle from "../../hooks/usePageTitle";
import TopBar from "../TopBar";
import styles from "./Navigation.module.scss";

type Props = {};

function Navigation(props: Props) {
	const gameName = useGameName();
	const title = usePageTitle();

	const toastPosition = window.innerWidth <= 600 ? "top-center" : "bottom-right";

	console.log();

	useEffect(() => {
		document.title = title;
	}, [title]);

	return (
		<div className={cx(styles.page, styles[gameName])}>
			<TopBar />

			<Outlet />
			<Toaster
				toastOptions={{
					duration: 3000,
					position: toastPosition,
					className: "toast",
					error: {
						style: {
							background: "var(--toast-error-bg)",
							color: "white",
						},
					},
					style: {
						background: "var(--toast-info-bg)",
						fontWeight: "bold",
						color: "black",
					},
				}}
			/>
		</div>
	);
}

export default Navigation;
