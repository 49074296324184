import { debug } from "loglevel";
import buildCandidate from "./buildCandidate";
import doPolygonsIntersect from "./doPolygonsIntersect";
import getPolygonLines from "./getPolygonLines";
import isInBounds from "./isInBounds";
import doesPolygonOverlapOthers from "./isInsidePolygon";
import polygonToString from "./polygonToString";

export default function getAllPotentials(polygons: TPolygonOnBoard[], type: TPolygonType): TPolygon[] {
	const potentials: TPolygon[] = [];
	polygons.sort((p1, p2) => p2.roundIndex - p1.roundIndex);

	for (let i = 0; i < polygons.length; i++) {
		const thisPoly = polygons[i];
		debug("Trying to add potentials for this polygon", polygonToString(thisPoly));
		const others = [...polygons, ...potentials];
		others.splice(i, 1);

		const toAdd = getPotentials(thisPoly, others, type);
		potentials.push(...toAdd);
	}

	return potentials;
}

function getPotentials(polygon: TPolygon, otherPolygons: TPolygon[], potentialType: TPolygonType): TPolygon[] {
	const candidates = getCandidates(polygon, potentialType);
	debug("Found the following candidates", candidates);
	// console.log({ polygon });
	// console.log({ otherPolygons });
	// console.log({ candidates });
	// console.log(JSON.parse(JSON.stringify(candidates)));

	const otherPolygonLines = otherPolygons.map((p) => getPolygonLines(p)).flat();

	console.log({ otherPolygonLines });
	//check out of bounds
	// check overlap

	const potentials = candidates.filter(
		(c) =>
			isInBounds(c) &&
			!doesPolygonOverlapOthers(c, otherPolygons, 1) &&
			!doPolygonsIntersect(getPolygonLines(c), otherPolygonLines),
	);

	debug("The following potentials have been added", potentials);
	return potentials;
}

//only squares atm - will have to add in logic for other shaped candidates
function getCandidates(polygon: TPolygon, candidateType: TPolygonType): TPolygon[] {
	const lines = getPolygonLines(polygon);

	const candidates: TPolygon[] = lines.map((l) => buildCandidate(candidateType, l));
	// const candidates: TPolygon[] = [c1, c2, c3, c4].map((c) => ({ type: candidateType, coords: c }));
	return candidates;
}
