import { Suspense, lazy } from "react";
import GameIntro from "../Shared/components/GameIntro";
import PageLoader from "../Site/components/PageLoader";
const WordGameApp = lazy(() => import("./Components/App"));

const WordsGameLazy = () => {
	return (
		<GameIntro>
			<Suspense fallback={<PageLoader />}>
				<WordGameApp />
			</Suspense>
		</GameIntro>
	);
};

export default WordsGameLazy;
