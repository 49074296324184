export default function getIntersectionOfLines(line1: Line, line2: Line): false | XY {
	const [l1s, l1e] = line1;
	const [l2s, l2e] = line2;

	const [x1, y1] = l1s;
	const [x2, y2] = l1e;
	const [x3, y3] = l2s;
	const [x4, y4] = l2e;

	return intersection(x1, y1, x2, y2, x3, y3, x4, y4);
}

function intersection(
	x1: number,
	y1: number,
	x2: number,
	y2: number,
	x3: number,
	y3: number,
	x4: number,
	y4: number,
): XY | false {
	// Check if none of the lines are of length 0
	if ((x1 === x2 && y1 === y2) || (x3 === x4 && y3 === y4)) {
		return false;
	}

	const denominator = (y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1);

	// Lines are parallel
	if (denominator === 0) {
		return false;
	}

	// let ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denominator
	// let ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denominator
	let ua = Math.round(((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denominator);
	let ub = Math.round(((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denominator);

	// console.log({ ua });
	// console.log({ ub });

	// is the intersection along the segments
	// if (ua < 0 || ua > 1 || ub < 0 || ub > 1) {
	// if (ua <= 0 || ua >= 1 || ub <= 0 || ub >= 1) {
	// if (ua <= -0.001 || ua >= 0.999 || ub <= -0.001 || ub >= 0.999) {
	if (ua <= 0 || ua >= 1 || ub <= 0 || ub >= 1) {
		return false;
	}

	// Return a object with the x and y coordinates of the intersection
	let x = x1 + ua * (x2 - x1);
	let y = y1 + ua * (y2 - y1);

	// console.log("intersection of lines ");
	// console.log([x1,y1])
	// console.log("Intersection at " + [x, y].join(","));
	return [x, y];
}
