import cx from "classnames";
import React from "react";
import styles from "./Tile.module.scss";

type Props = {
	isDragging?: boolean;
	budgeRight?: boolean;
	budgeLeft?: boolean;
	hide?: boolean;
	letter: string;
	onClick?: () => void;
	isActive?: boolean;
	isPreview?: boolean;
	isOnBoard?: boolean;
};

const Tile = React.forwardRef<HTMLButtonElement, Props>(
	({ isDragging, budgeRight, budgeLeft, letter, hide, onClick, isActive, isPreview, isOnBoard }: Props, ref) => {
		if (budgeLeft) {
			console.log("tile " + letter + " is budging left (margin right)");
			if (isDragging) {
				console.log("it is also dragging");
			}
		}
		if (budgeRight) {
			console.log("tile " + letter + " is budging right (margin left)");
			if (isDragging) {
				console.log("it is also dragging");
			}
		}

		return (
			<button
				className={cx(styles.tileButton, {
					[styles.isPreview]: isPreview,
					[styles.isDragging]: isDragging,
					[styles.budgeRight]: budgeRight,
					[styles.budgeLeft]: budgeLeft,
					[styles.active]: isActive,
					[styles.hide]: hide,
					[styles.isOnBoard]: isOnBoard,
				})}
				draggable="true"
				ref={ref}
				onClick={onClick}>
				<div
					className={cx(styles.tile, {
						// [styles.isDragging]: isDragging,
					})}>
					{letter}
				</div>
			</button>
		);
	},
);

export default React.memo(Tile);
