export function get4CornersOfHouse(house: THouseDef): XY[] {
	const { topLeft, width, height } = house;

	const x = topLeft[0];
	const y = topLeft[1];

	return [
		[x, y],
		[x + width, y],
		[x, y + height],
		[x + width, y + height],
	];
}
