/** calculates the "right turn distance" between two angles. Eg how far right we need to turn to get from one angle to the other
 * @param from start angle
 * @param to end angle
 *
 * @example getRightTurnDistance(100, 120) => 20
 * @example getRightTurnDistance(120, 100) => 340
 */
export default function getRightTurnDistance(from: number, to: number): number {
	if (to === from) {
		return 0;
	}

	if (to > from) {
		return to - from;
	}

	// from 100 to 120 => 20
	// from 330 to 180 => 190
	// from 330 to 270 =>

	return to + 360 - from;
}
