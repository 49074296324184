import cx from "classnames";
import { ReactNode, Suspense, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import HelpModal from "../../../Words/Components/HelpModal";
import capitaliseWord from "../../../Words/utils/capitaliseWord";
import useGameName from "../../hooks/useGameName";
import Button from "../Button";
import styles from "./GameIntro.module.scss";
import EnglishDictionary from "../../dictionaries/EnglishDictionary";
import { warn } from "loglevel";

type Props = {
	children: ReactNode;
};

function GameIntro({ children }: Props) {
	const gameName = useGameName();

	const [sp] = useSearchParams();
	const showHelp = sp.get("help");

	const [hasSeenIntro, setHasSeenIntro] = useState(false);

	useEffect(() => {
		if (gameName === "tiles") {
			if (!EnglishDictionary.isReady()) {
				warn("INSTANTIATING");
				EnglishDictionary.instantiate();
			}
		}
	}, [gameName]);

	let gameSpecificContent: ReactNode = null;

	switch (gameName) {
		case "tiles":
			gameSpecificContent = (
				<>
					<h2>Place the 12 tiles onto the board to make valid english words</h2>
					<img
						alt="Before and after of the board showing it being filled up with tiles to spell english words"
						className={styles.explainerImage}
						src="./tilesIntro.png"
					/>
				</>
			);
			break;
		case "words":
			gameSpecificContent = (
				<>
					<h2>6 Guesses to find the hidden word, see which letters you got right!</h2>
					<h2>You can make guesses of any length from 3-10 characters </h2>
					<h2>This week's category is Pokemon (gen 1) </h2>
				</>
			);
			break;
		case "flowers":
			gameSpecificContent = (
				<>
					{" "}
					<h1>Click on Flowers to bloom them!</h1>
					<h2>
						Flowers can't overlap other flowers <br />
						Make a connected path of orange petals so the bee can reach the honey
					</h2>
				</>
			);
			break;
		case "bubbles":
			gameSpecificContent = (
				<>
					{" "}
					<h1>Click on the dots to grow bubbles</h1>
					<h2>Bubbles will grow until they hit another one</h2>
					<h2>If your bubble is to big, you can shrink it by tapping its center again</h2>
					<h2>Cover all the squares with bubbles!</h2>
				</>
			);
			break;
		case "polygons":
			gameSpecificContent = (
				<>
					<h1>Join tiles onto the board to create the shape shown at the bottom</h1>
					<h2>Click on the board to choose where to add the next tile</h2>
				</>
			);
	}

	const intro = (
		<div className={cx(styles.gameIntro, styles[gameName])}>
			<div className="centerColumn">
				<h1>{capitaliseWord(gameName)}</h1>

				{gameSpecificContent}
				<Button onClick={() => setHasSeenIntro(true)}>Play</Button>
			</div>
			<div style={{ display: "none" }}>{children}</div>
		</div>
	);

	return (
		<>
			{!hasSeenIntro && intro}
			<div className="centerColumn">
				<HelpModal isOpen={!!showHelp} />
				<div className={cx({ [styles.hide]: !hasSeenIntro })}>
					<Suspense fallback={<></>}>{children}</Suspense>
				</div>
			</div>
		</>
	);
}

export default GameIntro;
