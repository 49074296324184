export default function getPolygonLines(polygon: TPolygonOrAmalgam): Line[] {
	const coords = polygon.coords;

	const lines: Line[] = [];

	for (let i = 0; i < coords.length - 1; i++) {
		const a = coords[i];
		const b = coords[i + 1];

		lines.push([a, b]);
	}
	lines.push([coords[coords.length - 1], coords[0]]);

	return lines;
}
