import { GLOBAL_MIN_LETTERS } from "../constants";
import { TWordsSliceState } from "../redux/wordsSlice";
import capitaliseWord from "./capitaliseWord";
import SpellCheckSingleton from "./fuseSingleton";

export default function updateCurrentGuessIssues(state: TWordsSliceState) {
	const { currentGuess, prevGuesses } = state;
	const maxSubmitLength = Math.min(state.knownAnswerLength || state.gameDef.characterLimit);
	const wordSet = SpellCheckSingleton.getWordSet();
	const fuse = SpellCheckSingleton.getFuse();

	state.currentGuessIssue = undefined;

	console.log(wordSet);
	console.log(fuse);

	if (prevGuesses.indexOf(currentGuess) !== -1) {
		state.currentGuessIssue = { type: "duplicate" };
		return;
	}
	if (wordSet && !wordSet.has(currentGuess)) {
		console.log({ wordSet });
		const issue: TNotInWordListIssue = { type: "not-in-word-list" };

		if (fuse) {
			const searchResults = fuse.search(currentGuess).filter((e) => e.item.length <= maxSubmitLength);
			if (searchResults[0]) {
				issue.suggestion = capitaliseWord(searchResults[0].item);
			}
		}
		state.currentGuessIssue = issue;
	}
	if (currentGuess.length < GLOBAL_MIN_LETTERS) {
		state.currentGuessIssue = { type: "insufficient-characters", count: currentGuess.length };
		return;
	}
}
