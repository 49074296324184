/**
 * Gets the angle between two points measured from 0 = vertical
 */
export function getAngleBetween(p1: XY, p2: XY) {
	if (p1.join(",") === p2.join(",")) {
		throw Error("these points are the same");
	}

	const angle = Math.atan2(p1[1] - p2[1], p1[0] - p2[0]);
	let angleInDeg = (angle * 180) / Math.PI - 90; //adjust for 0 = vertical
	// let angle2InDeg = (angle2 * 180) / Math.PI - 90; //adjust for 0 = vertical
	angleInDeg = (angleInDeg + 360) % 360;
	// angle2InDeg = (angle2InDeg + 360) % 360;

	// const angle = Math.atan(gradient);
	// let angleInDeg = 90 - (angle * 180) / Math.PI; //adjust for 0 = vertical

	// if (p1[0] > p2[0]) {
	// 	// reasons.. presumably period of the atan function
	// 	angleInDeg = angleInDeg - 180;
	// }

	return angleInDeg;
}
