import Fuse from "fuse.js";

class SpellCheckSingleton {
	private constructor() {}
	private static fuse: Fuse<string> | undefined;
	private static wordSet: Set<string> | undefined;

	static getFuse(): Fuse<string> | undefined {
		return SpellCheckSingleton.fuse;
	}

	static getWordSet(): Set<string> | undefined {
		return SpellCheckSingleton.wordSet;
	}

	static seed(words: string[]) {
		if (words && words.length) {
			SpellCheckSingleton.fuse = new Fuse<string>(words, { threshold: 0.35 });
			SpellCheckSingleton.wordSet = new Set(words);
		}
	}
	static instantiate(wordSet: Set<string> | undefined, fuse: Fuse<string> | undefined) {
		SpellCheckSingleton.wordSet = wordSet;
		SpellCheckSingleton.fuse = fuse;
	}
}

export default SpellCheckSingleton;
