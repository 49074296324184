import { trace } from "loglevel";

const LEEWAY = 0.01;

export default function arePointsSame(a: XY, b: XY) {
	if (a[0] === b[0] && a[1] === b[1]) {
		return true;
	}

	const diffX = Math.abs(a[0] - b[0]);
	const diffY = Math.abs(a[1] - b[1]);

	if (diffX < LEEWAY && diffY < LEEWAY) {
		trace(`Two points (${a[0]}, ${a[1]}) and (${b[0]}, ${b[1]}) have been considered the same point`);
		return true;
	}

	return false;
}
