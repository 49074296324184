import { Action, PayloadAction, createSlice } from "@reduxjs/toolkit";
import updateCandidates from "../utils/updateCandidates";
import checkAndUpdateWinStatus from "../utils/checkAndUpdateWinStatus";
import amalgamatePolygons from "../utils/amalgamatePolygons";

// export type boardState = TBoardDef

const initialState: TPolygonSliceState = {
	gameDefinition: {
		sequence: [],
		start: {
			type: "SQUARE",
			coords: [
				[0, 0],
				[0, 1],
				[1, 1],
				[1, 0],
			],
		},
		solution: [],
	},
	candidates: {},
	solution: { type: "AMALGAM", coords: [] },
	polygons: {},
	turnNumber: 0,
	currentAmalgam: { type: "AMALGAM", coords: [] },
	hoveredPolygonId: "",
};

export type TPolygonSliceState = {
	gameDefinition: TPolygonGameDef;
	solution: TAmalgam;
	candidates: { [key: string]: TPolygonOnBoard };
	polygons: { [key: string]: TPolygonOnBoard };
	turnNumber: number;
	currentAmalgam: TAmalgam;
	hoveredPolygonId: string;

	// errors: TErrorInfo;
	// validWords: TFoundWord[];
	won?: boolean;
};

export const polygonsSlice = createSlice({
	name: "polygons",
	initialState: initialState,
	reducers: {
		initialiseGame: (state, action: PayloadAction<TPolygonGameDef>) => {
			const gameDef = action.payload;
			state.gameDefinition = gameDef;
			state.candidates = {};
			state.polygons = {
				root_polygon: { ...gameDef.start, id: "root_polygon", isCandidate: false, roundIndex: -1, isRoot: true },
			};
			state.solution = { type: "AMALGAM", coords: gameDef.solution };
			state.turnNumber = 0;
			state.won = false;
			state.currentAmalgam = state.polygons["root_polygon"];
			updateCandidates(state);
		},
		activateCandidate: (state, action: PayloadAction<string>) => {
			const candidateId = action.payload;
			const candidate = state.candidates[candidateId];
			if (!candidate) {
				throw Error("No such candidate");
			}

			delete state.candidates[candidateId];
			state.polygons[candidateId] = candidate;
			state.turnNumber = state.turnNumber + 1;
			state.currentAmalgam = amalgamatePolygons(Object.values(state.polygons));
			checkAndUpdateWinStatus(state);
			updateCandidates(state);
		},
		undo: (state, action: Action) => {
			const mostRecentPolygon = Object.values(state.polygons).find(
				(p) => p.roundIndex === state.turnNumber - 1 && !p.isRoot,
			);

			if (!mostRecentPolygon) {
				return;
			}
			state.turnNumber = state.turnNumber - 1;
			delete state.polygons[mostRecentPolygon.id];
			state.currentAmalgam = amalgamatePolygons(Object.values(state.polygons));
			updateCandidates(state);
		},
		resetGame: (state, action: Action) => {
			state.candidates = {};
			state.polygons = {
				root_polygon: {
					...state.gameDefinition.start,
					id: "root_polygon",
					isCandidate: false,
					roundIndex: 0,
					isRoot: true,
				},
			};
			state.turnNumber = 0;
			state.won = false;
			state.currentAmalgam = amalgamatePolygons(Object.values(state.polygons));
			updateCandidates(state);
		},
		hoverPolygon: (state, action: PayloadAction<string>) => {
			state.hoveredPolygonId = action.payload;
		},
		unHoverPolygon: (state, action: PayloadAction<string>) => {
			// do this check so we don't get bugs if we go directly from one polygon to another
			if (state.hoveredPolygonId === action.payload) {
				state.hoveredPolygonId = "";
			}
		},
	},
});

export const { initialiseGame, resetGame, undo, hoverPolygon, unHoverPolygon, activateCandidate } =
	polygonsSlice.actions;
export default polygonsSlice.reducer;
